import { Button, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { ClassNameMap } from '@mui/styles';
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Skeleton } from '@mui/material';
import clsx from "clsx";
import { useModal } from "mui-modal-provider";
import { ShowFnOutput } from "mui-modal-provider/dist/types";
import Image from "next/image";
import { createElement, MouseEvent, useMemo, useState, VFC } from "react";
import { useQuestsState } from "../../hooks/atoms/useQuests";
import { useCallbackSafeRef } from "../../hooks/useCallbackSafeRef";
import { YoutubeVideoPlayerModal } from "../YoutubeVideoPlayerModal";
import { QuestConfig, QuestGroup, QuestStepProps } from "./quests.types";
import { getQuestConfigFromActiveQuest } from "./quests.util";
import { QuestVideoPlayerModalProps } from "./QuestVideoPlayerModal";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      maxWidth: 340,
      display: "flex",
      flexDirection: "column",
    },
    textAndBtns: {
      display: "flex",
      gap: theme.spacing(),
      flexDirection: "column",
      padding: theme.spacing(1.5, 2),
    },
    description: {},
    btnCont: {
      "&:empty": {
        display: "none",
      },
    },
    btn: {
      color: theme.palette.common.black,
      margin: theme.spacing(-0.75, 0, -0.75, -1),
      minWidth: 0,
    },
    imgWrapper: {
      padding: theme.spacing(1, 1, 0),
      width: "100%",
      "& > span": {
        position: "unset !important",
      },
      "& > span > img": {
        borderRadius: theme.shape.borderRadius * 1.5,
        objectFit: "contain",
        width: "100% !important",
        position: "relative !important",
        height: "unset !important",
      },
    },
    notReady: {
      "& > span > img": {
        height: "0 !important",
      },
    },
    svg: {
      borderRadius: theme.shape.borderRadius * 1.5,
      display: "block",
      minWidth: 268,
    },
    skeleton: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    noDisplay: {
      display: "none",
    },
    closeBtn: {
      margin: theme.spacing(-0.75, -1, -0.75, 0),
      minWidth: 0,
    },
    progressAndClose: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    progressTxt: {
      opacity: 0.5,
    },
  }),
  {
    classNamePrefix: "QuestGenericPopperContent",
  }
);

export type QuestGenericPopperContentJSSClassKey = keyof ReturnType<typeof useStyles>;

export type QuestGenericPopperContentProps = QuestStepProps & {
  classes?: Partial<ClassNameMap<QuestGenericPopperContentJSSClassKey>>;
  className?: string;
};

export const QuestGenericPopperContent: VFC<QuestGenericPopperContentProps> = ({
  className,
  classes: extClasses,
  stepConfig,
  onClose,
}) => {
  const classes = useStyles({
    classes: extClasses,
  });

  const { showModal } = useModal();

  const { activeQuest } = useQuestsState();

  const [imageReady, setImageReady] = useState(false);

  const config = useMemo<QuestConfig<QuestGroup> | undefined>(
    () => (activeQuest ? getQuestConfigFromActiveQuest(activeQuest) : undefined),
    [activeQuest]
  );

  const stepIndex: number | undefined = useMemo(
    () => config?.steps.findIndex((s) => s.id === activeQuest?.step),
    [config, activeQuest]
  );

  const isLastStep: boolean = useMemo(() => {
    if (!!config?.steps.length) return stepIndex === config?.steps.length;
    else return false;
  }, [stepIndex, config?.steps.length]);

  const progressDisplay = useMemo<string | undefined>(() => {
    if (!!config?.steps.length && stepIndex !== undefined && stepIndex >= 0) {
      return `${stepIndex + 1} of ${config.steps.length}`;
    }
  }, [config?.steps.length, stepIndex]);

  const handleClose = useCallbackSafeRef(() => {
    onClose?.();
  });

  const preventDefault = useCallbackSafeRef((e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  });

  const handleVideoClose = useCallbackSafeRef((modal: ShowFnOutput<QuestVideoPlayerModalProps>) => {
    modal.hide();
  });

  const handleWatchVideo = useCallbackSafeRef(() => {
    const modal = showModal(YoutubeVideoPlayerModal, {
      onClose: () => handleVideoClose(modal),
      videoLink: stepConfig.videoLink,
      videoOptions: stepConfig.videoOptions,
    });
  });

  return (
    <div className={clsx(classes.root, className)} onMouseOver={preventDefault} onClick={preventDefault}>
      {(() => {
        if (!!stepConfig.popoverConfig?.img) {
          return <>
            <Skeleton
              className={clsx(classes.skeleton, { [classes.noDisplay]: imageReady })}
              variant="rectangular"
              height={150}
              width="100%"
            />
            <div className={clsx(classes.imgWrapper, { [classes.notReady]: !imageReady })} onClick={stepConfig.type === "orb" ? handleClose : undefined}>
              <Image layout="fill" src={stepConfig.popoverConfig.img} onLoadingComplete={() => setImageReady(true)} />
            </div>
          </>;
        } else if (!!stepConfig.popoverConfig?.svg) {
          return (
            <div className={classes.imgWrapper}>
              {createElement(stepConfig.popoverConfig.svg, { className: classes.svg })}
            </div>
          );
        }
      })()}
      <div className={classes.textAndBtns}>
        <div className={classes.progressAndClose}>
          {!!progressDisplay && (
            <Typography className={classes.progressTxt}>
              {progressDisplay}
            </Typography>
          )}
          <Button onClick={handleClose} className={classes.closeBtn} variant="text">
            <CloseRoundedIcon fontSize="small" />
          </Button>
        </div>
        <Typography className={classes.description}>{stepConfig.popoverConfig?.description}</Typography>
        <div className={classes.btnCont}>
          {stepConfig.type === "orb" && (
            <Button className={classes.btn} onClick={handleClose}>
              {isLastStep ? "Close" : "Next"}
            </Button>
          )}
          {!!stepConfig.videoLink && (
            <Button className={classes.btn} onClick={handleWatchVideo}>
              Watch video
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
