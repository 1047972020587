import { Box, Collapse } from "@mui/material";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper, { PopperPlacementType } from "@mui/material/Popper";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { MouseEvent as ReactMouseEvent, MutableRefObject, useCallback, useEffect, useMemo, useState } from "react";
import { isValid } from "../../utils/dates";
import { isDescendant } from "../../utils/dom";
import { DateCalendar } from "../DateCalendar";
import { SmartClickAwayListener, SmartClickAwayListenerEventHandler } from "../SmartClickAwayListener";
import { useCalendarStyles } from "./micro";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popper: {
      zIndex: theme.zIndex.modal,
    },
    paper: {
      margin: theme.spacing(1, 0),
      minWidth: "13em",
      border: "1px solid #f7f8fc",
      "& .MuiInputAdornment-positionEnd": {
        display: "none",
      },
      "& .MuiPickersCalendarHeader-transitionContainer": {
        height: 26,
      },
    },
    picker: {
      backgroundColor: theme.colors.white,
      // Theme overrides because @material-ui/pickers tries to be too smart
      "& > .MuiPickersStaticWrapper-staticWrapperRoot": {
        backgroundColor: theme.colors.white,
      },
      "& .MuiPickersCalendarHeader-iconButton": {
        backgroundColor: theme.colors.white,
      },
    },
    timePickerBox: {
      margin: theme.spacing(0, 3, 3, 3),
    },
    timeDivider: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      flex: 1,
      textAlign: "center",
      height: "1em",
      padding: 0,
      borderWidth: 1,
      marginBottom: theme.spacing(4),
    },
    sectionTitle: {
      textAlign: "center",
      margin: theme.spacing(0, 2),
      cursor: "pointer",
    },
    divider: {
      flex: 1,
      height: 2,
      backgroundColor: theme.palette.divider,
      // backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    inlinePicker: {
      backgroundColor: "transparent",
    },
  })
);

export type DateTimePickerProps = {
  anchorEl: MutableRefObject<HTMLInputElement | undefined>;
  value?: Date | null;
  placement?: PopperPlacementType;
  onChange?: (name: string, value: Date) => void;
  inlinePicker?: boolean;
  staticPicker?: boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
};

export const DateTimePicker: React.VFC<DateTimePickerProps> = ({
  anchorEl,
  value = new Date(),
  onChange,
  placement = "bottom-end",
  inlinePicker,
  staticPicker,
  disablePast = true,
  disableFuture = false,
}) => {
  const classes = useStyles();
  const calendarClasses = useCalendarStyles();

  const [open, setOpen] = useState(false);

  /**
   * Register event listeners to show/hide calendar dialog
   */
  useEffect(() => {
    const el = anchorEl.current;
    if (!el) return;

    const toggleOpen = (e: MouseEvent) => {
      if (
        (open && e.relatedTarget === el) ||
        // Note: e.relatedTarget is null in safari here. see handleMonthNavigation.
        isDescendant("reclaim-datetime-picker-ui", e.relatedTarget) ||
        isDescendant("reclaim-datetime-picker-ui", e.target)
      ) {
        return;
      }

      setOpen(document.activeElement === el);

      // TODO (SS): This prevents cases where DatePicker fires onChange right when
      // the popper opens initially (past dates). When this happens the date picker does
      // not show on focus. Need to consider another option here but right now we have no control
      // when this event is fired. Other option would be to remove the setOpen(false) from handleChange
      // which would have some UX to consider.
      setTimeout(() => {
        if (!open) setOpen(document.activeElement === el);
      }, 50);
    };

    el.autocomplete = "off";
    el.addEventListener("focus", toggleOpen);
    el.addEventListener("blur", toggleOpen);

    return () => {
      el.removeEventListener("focus", toggleOpen);
      el.removeEventListener("blur", toggleOpen);
    };
  }, [anchorEl, open]);

  const handleClickOutside = useCallback<SmartClickAwayListenerEventHandler>(
    (e, clickAwayHandled) => {
      if (
        document.activeElement !== anchorEl.current &&
        e.target !== anchorEl.current &&
        "relatedTarget" in e &&
        !isDescendant("reclaim-datetime-picker-ui", e.relatedTarget)
      )
        setOpen(false);
      else clickAwayHandled();
    },
    [anchorEl]
  );

  const handleChange = useCallback(
    (date) => {
      const name = anchorEl.current?.getAttribute("name");

      if (!!name && onChange && isValid(date as Date)) {
        onChange(name as string, date as Date);
        setOpen(false);
      }
    },
    [anchorEl, onChange]
  );

  /**
   * This prevents the blur event from triggering early when the date picker is used in safari. This is an
   * issue in safari because the relatedTarget is not attached to the blur event (see toggleOpen function)
   */
  const handleMouseDown = useCallback((e: ReactMouseEvent<Element>) => {
    e.stopPropagation();
    e.preventDefault();
  }, []);

  const datePicker = useMemo(
    () => (
      <DateCalendar
        slotProps={{
          rightArrowIcon: {
            onMouseDown: handleMouseDown,
          },
          leftArrowIcon: {
            onMouseDown: handleMouseDown,
          },
        }}
        openTo="day"
        disablePast={disablePast}
        disableFuture={disableFuture}
        value={value}
        onChange={handleChange}
      />
    ),
    [value, disablePast, disableFuture, handleMouseDown, handleChange]
  );

  const inlinePickerNode = useMemo(
    () =>
      !staticPicker ? (
        <Box className="reclaim-datetime-picker-ui">
          <Collapse in={open}>
            <Box className={clsx(classes.picker, classes.inlinePicker, calendarClasses.micro)}>{datePicker}</Box>
          </Collapse>
        </Box>
      ) : (
        <Box className={clsx(classes.picker, classes.inlinePicker, calendarClasses.micro)}>{datePicker}</Box>
      ),
    [staticPicker, open, datePicker, classes.picker, classes.inlinePicker, calendarClasses.micro]
  );

  return !inlinePicker ? (
    <SmartClickAwayListener onClickAway={handleClickOutside}>
      <Popper
        className={clsx("reclaim-datetime-picker-ui", classes.popper)}
        onMouseDown={handleMouseDown}
        open={open}
        anchorEl={anchorEl.current}
        placement={placement}
        role={undefined}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: /^bottom/i.test(placement) ? "right top" : "right bottom",
            }}
          >
            <Paper className={clsx("reclaim-datetime-picker-ui", classes.paper, classes.picker)}>{datePicker}</Paper>
          </Grow>
        )}
      </Popper>
    </SmartClickAwayListener>
  ) : (
    inlinePickerNode
  );
};
