import makeStyles from "@mui/styles/makeStyles";
import { ClassNameMap } from "@mui/styles";
import clsx from "clsx";
import {
  DateCalendar as MuiDateCalendar,
  DateCalendarProps as MuiDateCalendarProps,
} from "@mui/x-date-pickers/DateCalendar";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: 200,
    },
    day: {
      borderRadius: 999999999,
    },
  }),
  {
    classNamePrefix: "DateCalendar",
  }
);

export type DateCalendarJSSClassKey = keyof ReturnType<typeof useStyles>;

export type DateCalendarProps<T_DATE> = Omit<MuiDateCalendarProps<T_DATE>, "classes"> & {
  classes?: Partial<ClassNameMap<DateCalendarJSSClassKey>>;
  MuiDateCalendarClasses?: MuiDateCalendarProps<T_DATE>["classes"];
};

export const DateCalendar = <T_DATE extends unknown>({
  className,
  classes: extClasses,
  MuiDateCalendarClasses,
  slotProps,
  views = ["day"],
  ...rest
}: DateCalendarProps<T_DATE>) => {
  const classes = useStyles({
    classes: extClasses,
  });

  /********************/
  /*   custom hooks   */
  /********************/

  /********************/
  /*     useState     */
  /********************/

  /********************/
  /* useMemo & consts */
  /********************/

  /********************/
  /*    useCallback   */
  /********************/

  /********************/
  /*    useEffects    */
  /********************/

  /********************/
  /*       JSX        */
  /********************/

  return (
    <MuiDateCalendar
      className={clsx(classes.root, className)}
      classes={MuiDateCalendarClasses}
      slotProps={{
        ...slotProps,
        day: {
          ...slotProps?.day,
          classes: {
            root: classes.day,
          },
        },
      }}
      views={views}
      {...rest}
    />
  );
};
